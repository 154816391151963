.main-content {
  padding-left: 2%;
  padding-right: 2%;
}

@media only screen and (max-width: 100rem) {
  .main-content {
    padding-left: 3%;
    padding-right: 3%;
  }
}

@media only screen and (max-width: 90rem) {
  .main-content {
    padding-left: 3%;
    padding-right: 3%;
  }
}

@media only screen and (max-width: 82rem) {
  .main-content {
    padding-left: 3%;
    padding-right: 3%;
  }
}

@media only screen and (max-width: 71rem) {
  .main-content {
    padding-left: 3%;
    padding-right: 3%;
  }
}

@media only screen and (max-width: 50rem) {
  .main-content {
    padding-left: 3%;
    padding-right: 3%;
  }
}

.desktop-color {
  color: rgb(11, 68, 11);
}

.btn {
  color: #fff;
  background-color: #59caee;
  border-color: #59caee;
}

.btn:hover {
  background-color: #222462;
  border-color: #59caee;
  color: #59caee;
}

.btn-cancel {
  color: #fff;
  background-color: gray;
  border-color: gray;
}

.btn-cancel:hover {
  background-color: gray;
  border-color: gray;
  color: black;
}

.btn-delete {
  color: #fff;
  background-color: red;
  border-color: red;
}

.btn-delete:hover {
  background-color: red;
  border-color: red;
  color: black;
}

.btn-create {
  color: #fff;
  background-color: rgb(16, 187, 16);
  border-color: rgb(16, 187, 16);
}

.btn-create:hover {
  color: gray;
  background-color: rgb(16, 187, 16);
  border-color: rgb(16, 187, 16);
}

.btn-token {
  padding: 5%;
}

.btn-detail {
  background-color: #111540;
  border-color: #111540;
  color: white;
}

.page-item.active .page-link {
  z-index: 3;
  color: #fff;
  background-color: #59caee !important;
  border-color: #59caee !important;
}

.home-logo {
  margin-top: 23%;
  width: 60%;
}

.home-velo-logo {
  width: 30%;
}

.dashboard-logo {
  margin-bottom: 5%;
  width: 85%;
}

.barcode {
  display: block;
  height: 12rem;
  padding-top: 0;
  padding-bottom: 2rem;
}

.no-margin {
  margin: 0 !important;
}

.rw-color-2 {
  color: #3c3d75;
}

.rw-color-3 {
  color: #59caee;
}

.rw-color-4 {
  color: rgb(128, 128, 128);
}

.rw-color-5 {
  color: #111540;
}

.table-background,
table,
th,
td {
  background-color: rgb(238, 247, 248) !important;
  border: 2px solid white !important;
  border-collapse: collapse;
}

.bulk {
  display: none;
}

.bulkmenu {
  border: none;
  background: none;
}

.bulkmenu-show {
  display: block;
  border: none;
  background: none;
}

.bulkmenu:hover {
  color: #222462;
}

.table {
  width: 100%;
  margin-bottom: 1rem;
  color: black !important;
}

.table-color-gray {
  color: rgb(78, 78, 78) !important;
}

.list-group-item-action {
  border: none;
}

.demo-map {
  width: 80%;
  height: 50vh;
  border: 5px outset gray;
}

.map {
  width: 40%;
  height: 30vh;
  border: 5px outset gray;
}

.checkbox {
  width: 2%;
}

.td-padding {
  padding: 0% !important;
}

.cursor {
  cursor: pointer;
}

.background-main {
  background-color: #444aff;
  background-image: linear-gradient(
    304deg,
    #444aff -19%,
    #232562,
    #0f143d 100%
  );
  background-size: cover;
}

.background-main-singlecolor {
  /* background-image: url("./roshandevice.css"); */
  background-color: #0f143d;
  background-size: cover;
}

.profile {
  margin-top: 0% !important;
}

.form-control-user {
  border-radius: 0.35rem !important;
}

.timezone-form {
  width: 104%;
  height: 66px;
  color: gray;
}

.countform {
  width: 20%;
}

.companyform {
  width: 100%;
}

.home-forms {
  font-size: 0.8rem;
  border-radius: 1rem;
  padding: 1.5rem 1rem;
}

.home-login-forms {
  font-size: 1rem;
  border-radius: 1rem !important;
  padding: 0.75rem 1rem;
}

.text-color {
  color: white;
}

.main-card {
  margin-top: 20%;
}

.testsperformed {
  height: 360px;
}

@keyframes ldio-9u8jung592p {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(90deg);
  }
}

.ldio-9u8jung592p > div {
  position: absolute;
  width: 800px;
  height: 800px;
  top: 0;
  left: 0;
}

.left-motion {
  transform: rotate(270deg);
}

.ldio-9u8jung592p div:nth-child(2) div {
  position: absolute;
  left: -800px;
  width: 2400px;
  height: 2400px;
  opacity: 0.4;
  transform-origin: 1200px 1200px;
  animation: ldio-9u8jung592p linear infinite 16.666666666666664s;
}

.ldio-9u8jung592p div:nth-child(2) div:nth-child(1) {
  top: -2000px;
  border-radius: 720px;
  background: #a9f6ff;
  animation-duration: 5.55s;
}

.ldio-9u8jung592p div:nth-child(2) div:nth-child(2) {
  top: -2080px;
  border-radius: 760px;
  background: #00e6ff;
  animation-duration: 8.333333333333332s;
}

.ldio-9u8jung592p div:nth-child(2) div:nth-child(3) {
  top: -2160px;
  border-radius: 800px;
  background: #0be7ff;
}

.loadingio-spinner-fluid-br2vzx53rm8 {
  left: auto;
  width: 600px;
  height: 100%;
  position: fixed;
}

.ldio-9u8jung592p {
  width: 100%;
  height: 100%;
  position: relative;
  transform: translateZ(0) scale(1);
  backface-visibility: hidden;
  transform-origin: 0 0;
}

.ldio-9u8jung592p div {
  box-sizing: content-box;
}

.mainpages {
  display: grid !important;
  grid-template-rows: min-content minmax(max-content, 70vh) minmax(
      max-content,
      20vh
    ) !important;
  grid-template-columns:
    [sidebar-start] 14rem [sidebar-end full-start] minmax(6rem, 1fr)
    [center-start] repeat(8, [col-start] minmax(min-content, 14rem) [col-end])
    [center-end] minmax(6rem, 1fr)
    [full-end] !important;
}

.mainside {
  grid-row: 1 / -1 !important;
  grid-column: sidebar-start / sidebar-end !important;
}

.maintopbar {
  grid-row: 1 / 2 !important;
  grid-column: sidebar-end / full-end !important;
}

.copyright {
  grid-row: 3/4 !important;
  grid-column: sidebar-end / full-end !important;
  align-self: end !important;
}

.maincontent {
  grid-row: 2 /3 !important;
  grid-column: sidebar-end / full-end !important;
}

h4,
h5,
h6,
th,
tr {
  font-size: 1rem !important;
}

.center {
  text-align: center;
}

@media only screen and (max-width: 62.5rem) {
  .mainpages {
    grid-template-rows: min-content minmax(70vh, max-content) minmax(
        30vh,
        max-content
      ) !important;
  }
}

@media only screen and (max-width: 58rem) {
  .barcode {
    display: block;
    height: 10rem;
    padding-top: 0;
    padding-bottom: 0.5rem;
  }
}

@media only screen and (max-width: 55rem) {
  .barcode {
    display: block;
    height: 8rem;
    padding-top: 0;
    padding-bottom: 0.5rem;
  }
}

@media only screen and (max-width: 50rem) {
  .mainpages {
    grid-template-rows: min-content minmax(90vh, max-content) minmax(
        max-content,
        10vh
      ) !important;
  }
  .mainside {
    display: none;
  }
  .maintopbar {
    grid-row: 1 / 2 !important;
    grid-column: 1 / -1 !important;
  }
  .maincontent {
    grid-row: 2 /3 !important;
    grid-column: 1 / -1 !important;
  }
  .sidebar {
    display: none !important;
  }
  h4,
  h5,
  h6,
  td,
  th,
  tr {
    font-size: 0.6rem !important;
  }
  .barcode {
    display: block;
    height: 12rem;
    padding-top: 0;
    padding-bottom: 0.5rem;
  }
}

@media only screen and (max-width: 37.5rem) {
  .mainpages {
    grid-template-rows: min-content minmax(100vh, max-content) !important;
    grid-template-columns: 1fr !important;
  }
  .mainside {
    display: none;
  }
  .maintopbar {
    grid-row: 1 / 2 !important;
    grid-column: 1 / -1 !important;
  }
  .maincontent {
    grid-row: 2 /3 !important;
    grid-column: 1 / -1 !important;
  }
  .sidebar {
    display: none !important;
  }
  td,
  th,
  tr {
    font-size: 0.6rem !important;
  }
  .barcode {
    display: block;
    height: 12rem;
    padding-top: 0;
    padding-bottom: 0.5rem;
  }
  .demo-map {
    width: 80%;
    height: 40vh;
    border: 5px outset gray;
  }
  .map {
    width: 70%;
    height: 20vh;
    border: 5px outset gray;
  }
  .copyright {
    grid-row: 3/4 !important;
    grid-column: 1 / -1 !important;
  }
  .container-fluid {
    width: 100vw;
  }
  .container {
    width: 100vw;
    padding-left: 0.75rem;
    padding-right: 0.75rem;
  }
}

.sidebar {
  position: fixed !important;
  overflow-y: auto;
}

.loginpage {
  grid-row: 1/ -1;
  grid-column: 1 / -1;
}

.font-small {
  font-size: 0.8rem !important;
  padding: 0.9rem 1rem !important;
}

.cancelbtn {
  background: gray !important;
  border: gray !important;
}

.cancelbtn:hover {
  color: white !important;
  background-color: rgb(84, 84, 84) !important;
}

@keyframes ldio-2xi5lb84zo6 {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.ldio-2xi5lb84zo6 div {
  left: 94px;
  top: 48px;
  position: absolute;
  animation: ldio-2xi5lb84zo6 linear 1.0204081632653061s infinite;
  background: #59caee;
  width: 12px;
  height: 24px;
  border-radius: 6px / 12px;
  transform-origin: 6px 52px;
}

.ldio-2xi5lb84zo6 div:nth-child(1) {
  transform: rotate(0deg);
  animation-delay: -0.935374149659864s;
  background: #59caee;
}

.ldio-2xi5lb84zo6 div:nth-child(2) {
  transform: rotate(30deg);
  animation-delay: -0.8503401360544218s;
  background: #59caee;
}

.ldio-2xi5lb84zo6 div:nth-child(3) {
  transform: rotate(60deg);
  animation-delay: -0.7653061224489796s;
  background: #59caee;
}

.ldio-2xi5lb84zo6 div:nth-child(4) {
  transform: rotate(90deg);
  animation-delay: -0.6802721088435374s;
  background: #59caee;
}

.ldio-2xi5lb84zo6 div:nth-child(5) {
  transform: rotate(120deg);
  animation-delay: -0.5952380952380952s;
  background: #59caee;
}

.ldio-2xi5lb84zo6 div:nth-child(6) {
  transform: rotate(150deg);
  animation-delay: -0.5102040816326531s;
  background: #59caee;
}

.ldio-2xi5lb84zo6 div:nth-child(7) {
  transform: rotate(180deg);
  animation-delay: -0.4251700680272109s;
  background: #59caee;
}

.ldio-2xi5lb84zo6 div:nth-child(8) {
  transform: rotate(210deg);
  animation-delay: -0.3401360544217687s;
  background: #59caee;
}

.ldio-2xi5lb84zo6 div:nth-child(9) {
  transform: rotate(240deg);
  animation-delay: -0.25510204081632654s;
  background: #59caee;
}

.ldio-2xi5lb84zo6 div:nth-child(10) {
  transform: rotate(270deg);
  animation-delay: -0.17006802721088435s;
  background: #59caee;
}

.ldio-2xi5lb84zo6 div:nth-child(11) {
  transform: rotate(300deg);
  animation-delay: -0.08503401360544217s;
  background: #59caee;
}

.ldio-2xi5lb84zo6 div:nth-child(12) {
  transform: rotate(330deg);
  animation-delay: 0s;
  background: #59caee;
}

.loadingio-spinner-spinner-9bu8c120xat {
  width: 200px;
  height: 200px;
  display: inline-block;
  overflow: hidden;
  background: #ffffff;
}

.ldio-2xi5lb84zo6 {
  width: 100%;
  height: 100%;
  position: relative;
  transform: translateZ(0) scale(1);
  backface-visibility: hidden;
  transform-origin: 0 0;
}

.ldio-2xi5lb84zo6 div {
  box-sizing: content-box;
}

.border-bottom {
  border-bottom: 1px solid rgba(0, 0, 0, 0.125) !important;
}

.actual-color {
  color: #59caee !important;
  cursor: pointer;
}

.leaflet-container {
  width: 100%;
  height: 100%;
  z-index: 1;
}

/* 
@media only screen and (max-width: 80rem) {
  .non-collapse-col {
  }
} */

.concentration {
  font-size: 13px;
}

@media only screen and (max-width: 37.5rem) {
  .concentration {
    font-size: 0.6rem;
  }
}

#tog {
  cursor: pointer;
}

.center-frame {
  position: relative;
  padding-bottom: 56.25%; /* 16:9 aspect ratio */
  height: 0;
  width: 90%;
  margin: 50px auto;
}

.center-frame iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 80%;
  height: 60%;
}

.pdf-container {
  width: 50%;
  height: 100%;
  margin: 50px auto;
}

@media only screen and (max-width: 37.5rem) {
  .pdf-container {
    width: 100%;
  }
}

.pdf-document {
  width: 100%;
}

.pdf-document .react-pdf__Page > * {
  max-width: 100%;
  height: auto !important;
}

.cartridge-wrapper {
  position: relative;
  padding-bottom: 56.25%; /* 16:9 aspect ratio */
  height: 0;
  width: 50%;
  margin: 50px auto;
}

@media only screen and (max-width: 37.5rem) {
  .cartridge-wrapper {
    width: 90%;
  }
}

.cartridge-wrapper img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.pdf-nav {
  display: flex;
  justify-content: center;
  align-items: center;
}
